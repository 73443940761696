import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

const SubscriptionDescription: FC<{
  description: string
  setDescription: (description: string) => void
}> = ({ description, setDescription }) => {
  return (
    <FormControl>
      <FormLabel mb={1}>
        <Trans>Description</Trans>
      </FormLabel>
      <Input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </FormControl>
  )
}

export default SubscriptionDescription
