import analytics from '@capturi/analytics'
import { commentsAPI } from '@capturi/api-comments'
import {
  SpeakerStatistics,
  useUpdateIsReviewed,
} from '@capturi/api-conversations'
import { mutateAPI } from '@capturi/api-utils'
import { useAudioContext, useAudioTime } from '@capturi/audio'
import { Speaker, useCurrentUser } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { useToast } from '@capturi/ui-components'
import styled from '@capturi/ui-theme'
import { Box, Flex, FlexProps, Stack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useShortcut } from '@shopify/react-shortcuts'
import { useSnippetCRUD as useAudioSnippetCRUD } from 'features/library'
import React, { useEffect, useRef, useState } from 'react'
import { useEffectOnce, usePrevious, useSlider } from 'react-use'

import CreateCommentModal from '../Coaching/Comments/CreateCommentModal'
import AgentAvatar from './components/AgentAvatar'
import AudioControls from './components/AudioControls'
import CustomerAvatar from './components/CustomerAvatar'
import SeekBar from './components/SeekBar'
import TalkLine from './components/TalkLine'
import {
  talkLineHeightPx,
  userRowHeightPx,
  userRowSpacingPx,
} from './constants'
import { Hit } from './types'

type AudioProps = {
  duration: number
  initialTimestamp?: number
  agentUid: string
  salesPersonSpeakTimePercent?: number
  salesPerson?: SpeakerStatistics
  otherPerson?: SpeakerStatistics
  otherPersonSpeakTimePercent?: number
  customer?: string
  conversationUid: string
  conversationDateTime: Date
  hits?: Hit[]
  hitMarkTooltipContainerRef: React.RefObject<HTMLDivElement>
  qaIsReviewed: boolean
  hasAudio: boolean
  hasConsent: boolean
  qaReviewedByUserUid: string | null
  qaReviewedDate: Date | null
}

const GridAreas = {
  Users: 'audio-player__Users',
  UserSeekers: 'audio-player__user-seeker',
  UserPercentage: 'audio-player__user-percentage',
  Play: 'audio-player__play',
  Seeker: 'audio-player__seeker',
  Timestamp: 'audio-player__timestamp',
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: '${GridAreas.Users} ${GridAreas.UserSeekers} ${GridAreas.UserPercentage} ';
  column-gap: 1rem;
  align-items: flex-start;
`

const UserRow: React.FC<FlexProps> = (props) => (
  <Flex align="center" h={userRowHeightPx} {...props} />
)

const Audio: React.FC<AudioProps> = ({
  agentUid,
  salesPerson,
  salesPersonSpeakTimePercent = 0,
  otherPerson,
  otherPersonSpeakTimePercent = 0,
  duration,
  initialTimestamp,
  conversationUid,
  conversationDateTime,
  hits,
  hitMarkTooltipContainerRef,
  qaIsReviewed,
  hasAudio,
  qaReviewedByUserUid,
  qaReviewedDate,
  hasConsent,
}) => {
  const {
    audioState,
    getTime,
    isPlaying,
    sourceRef,
    play,
    playbackRate,
    pause,
    seek,
    setPlaybackRate,
    playbackContext,
    setPlaybackContext,
    stop,
    toggle,
    isAudioLoading,
  } = useAudioContext(`/playback/audio/${conversationUid}`, {
    rollbackSeconds: 3,
  })

  const { mutate: updateIsReviewed } = useUpdateIsReviewed(conversationUid)
  const toast = useToast()
  const previousConversationUid = usePrevious(conversationUid)
  useEffect(() => {
    if (previousConversationUid !== conversationUid) {
      stop()
    }
  }, [conversationUid, previousConversationUid, stop])

  const { getUserByUid } = useUsers()

  const [time = 0] = useAudioTime()

  const ref = useRef(null)
  const { isSliding, value: sliderValue } = useSlider(ref)
  const wasSliding = usePrevious(isSliding)

  const [shouldResumePlaying, setShouldResumePlaying] = useState(false)
  const [isCreateCommentOpen, setIsCreateCommentOpen] = useState(false)
  const [isKeyboardShortcutsEnabled, setIsKeyboardShortcutsEnabled] =
    useState(true)
  const enableKeyboardShortcuts = React.useCallback(() => {
    setIsKeyboardShortcutsEnabled(true)
  }, [])
  const disableKeyboardShortcuts = React.useCallback(() => {
    setIsKeyboardShortcutsEnabled(false)
  }, [])
  const user = useCurrentUser()

  useShortcut([' '], () => {
    // NOTE: useShortcut has options object as second parameter with an "ignoreInput" param
    // but the options does not take effect.
    if (!isKeyboardShortcutsEnabled) return

    if (audioState.paused && sourceRef.current?.src === '') play()
    audioState.paused ? play() : pause()
  })

  const audioSnippetActions = useAudioSnippetCRUD()

  const seekerValue = React.useMemo(() => {
    if (!user.permissions.playback) return 0
    if (isSliding) {
      return sliderValue
    }

    return time / duration || 0
  }, [user.permissions.playback, isSliding, time, duration, sliderValue])

  useEffect(() => {
    if (wasSliding && !isSliding) {
      seek(sliderValue * duration)
    }
  }, [wasSliding, isSliding, sliderValue, duration, seek])

  useEffectOnce(() => {
    if (initialTimestamp !== undefined) {
      seek(initialTimestamp)
    }
  })

  const { sales: salesPersonHits, other: otherPersonHits } =
    React.useMemo(() => {
      if (!hits) return { sales: undefined, other: undefined }

      return hits.reduce<{ sales: Hit[]; other: Hit[] }>(
        (acc, hit) => {
          const { speakerId } = hit
          if (
            speakerId === undefined ||
            speakerId === Speaker.Employee ||
            speakerId === 'User'
          ) {
            acc.sales.push(hit)
          } else if (speakerId === Speaker.Customer || speakerId === 'Other') {
            acc.other.push(hit)
          }
          return acc
        },
        { sales: [], other: [] },
      )
    }, [hits])

  const handleHitClick = React.useCallback(
    (hit: Hit) => {
      play(hit.timestamp, true)
      setPlaybackContext({
        ...playbackContext,
        hitId: hit.id,
      })
    },
    [play, playbackContext, setPlaybackContext],
  )

  const handleOpenModal = React.useCallback(() => {
    disableKeyboardShortcuts()
    setShouldResumePlaying(!audioState.paused)
    pause()
  }, [audioState.paused, disableKeyboardShortcuts, pause])

  const handleCloseModal = React.useCallback(() => {
    enableKeyboardShortcuts()
    if (shouldResumePlaying) {
      play(seekerValue * duration)
    }
  }, [
    duration,
    enableKeyboardShortcuts,
    play,
    seekerValue,
    shouldResumePlaying,
  ])

  const handleOpenCreateSnippet = React.useCallback(() => {
    handleOpenModal()
    analytics.event('library_conversationDetails_openCreateSnippet')
    audioSnippetActions.createSnippet(
      conversationUid,
      getTime(),
      duration,
      undefined,
      handleCloseModal,
    )
  }, [
    audioSnippetActions,
    conversationUid,
    duration,
    getTime,
    handleCloseModal,
    handleOpenModal,
  ])

  const handleToggleIsReviewed = React.useCallback(() => {
    const { name } = getUserByUid(agentUid)
    analytics.event('conversationPlayer--is-reviewed--pressed', {
      isReviewed: !qaIsReviewed,
      agent: name,
      conversationId: conversationUid,
    })

    updateIsReviewed(
      { isReviewed: !qaIsReviewed },
      {
        onError: (error) => {
          toast({
            title: t`Could not update reviewed state`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }, [
    qaIsReviewed,
    toast,
    updateIsReviewed,
    getUserByUid,
    agentUid,
    conversationUid,
  ])
  const handleOpenCreateComment = React.useCallback(() => {
    handleOpenModal()
    setIsCreateCommentOpen(true)
  }, [handleOpenModal])

  const handleCloseCreateComment = React.useCallback(() => {
    handleCloseModal()
    mutateAPI(commentsAPI.getComments(conversationUid))
    setIsCreateCommentOpen(false)
  }, [conversationUid, handleCloseModal])

  const handleSetSeekDelta = React.useCallback(
    (number: number): void => {
      const newPosition = getTime() + number
      seek(newPosition)
      analytics.event(
        number > 0 ? 'conversationPlayer_forward' : 'conversationPlayer_revert',
      )
    },
    [getTime, seek],
  )
  const handleSeekForward = React.useCallback(
    () => handleSetSeekDelta(10),
    [handleSetSeekDelta],
  )
  const handleSeekBackwards = React.useCallback(
    () => handleSetSeekDelta(-10),
    [handleSetSeekDelta],
  )

  const toggleSpeed = React.useCallback((): void => {
    setPlaybackRate((currentRate) => {
      let newRate = 1
      if (currentRate < 2) {
        newRate = currentRate + 0.25
      }
      analytics.event('conversationPlayer_changePlaybackRate', {
        playbackRate: newRate,
      })
      return newRate
    })
  }, [setPlaybackRate])

  const onTogglePlay = React.useCallback((): void => {
    const willPlay = toggle()

    if (willPlay) {
      analytics.event('analytics_Conversations_PlayAudio', {
        uid: conversationUid,
        date: conversationDateTime.toUTCString(),
      })
    } else {
      analytics.event('conversationPlayer_pause')
    }
  }, [conversationDateTime, conversationUid, toggle])

  return (
    <>
      <Flex direction="column" width="100%">
        <Box
          px={5}
          pt={5}
          pb={3}
          bg="accents.lightBackground.default"
          borderWidth={1}
          borderRadius={'10px 10px 0px 0px'}
          borderColor="border.light"
          overflow="hidden"
        >
          <Grid>
            <Stack
              spacing={userRowSpacingPx}
              align="flex-end"
              justify="center"
              gridArea={GridAreas.Users}
            >
              {agentUid && <AgentAvatar agentUid={agentUid} />}
              {otherPerson && <CustomerAvatar />}
            </Stack>
            <Box
              ref={ref}
              gridArea={GridAreas.UserSeekers}
              position="relative"
              pb={1}
            >
              <Stack spacing={0}>
                {salesPerson && (
                  <UserRow>
                    <TalkLine
                      seekerValue={seekerValue}
                      duration={duration}
                      data={salesPerson.when}
                      hits={salesPersonHits ?? []}
                      hitsLocation="top"
                      hitTooltipContainerRef={hitMarkTooltipContainerRef}
                      height="100%"
                      width="100%"
                      fill="primary.500"
                      onHitClick={handleHitClick}
                    />
                  </UserRow>
                )}
                {salesPerson && otherPerson && (
                  <Box h={userRowSpacingPx} w="100%" position="relative">
                    <SeekBar value={seekerValue * 100} />
                  </Box>
                )}
                {otherPerson && (
                  <UserRow>
                    <TalkLine
                      seekerValue={seekerValue}
                      duration={duration}
                      data={otherPerson.when}
                      hits={otherPersonHits ?? []}
                      hitsLocation="bottom"
                      hitTooltipContainerRef={hitMarkTooltipContainerRef}
                      height="100%"
                      width="100%"
                      fill="primary.300"
                      onHitClick={handleHitClick}
                    />
                  </UserRow>
                )}
              </Stack>
            </Box>
            <Stack
              gridArea={GridAreas.UserPercentage}
              spacing={userRowSpacingPx}
              align="flex-end"
              justify="center"
            >
              {salesPerson && (
                <UserRow alignItems="flex-end">
                  <Box h={talkLineHeightPx} lineHeight={talkLineHeightPx}>
                    {`${Math.round(salesPersonSpeakTimePercent)} %`}
                  </Box>
                </UserRow>
              )}
              {otherPerson && (
                <UserRow alignItems="flex-start">
                  <Box h={talkLineHeightPx} lineHeight={talkLineHeightPx}>
                    {`${Math.round(otherPersonSpeakTimePercent)} %`}
                  </Box>
                </UserRow>
              )}
            </Stack>
          </Grid>
        </Box>
      </Flex>
      <AudioControls
        key={conversationUid}
        onTogglePlay={onTogglePlay}
        user={user}
        isPlaying={isPlaying}
        isAudioLoading={isAudioLoading}
        time={time}
        duration={duration}
        handleSeekBackwards={handleSeekBackwards}
        handleSeekForward={handleSeekForward}
        toggleSpeed={toggleSpeed}
        playbackRate={playbackRate}
        handleOpenCreateComment={handleOpenCreateComment}
        handleOpenCreateSnippet={handleOpenCreateSnippet}
        onToggleIsReviewed={handleToggleIsReviewed}
        qaIsReviewed={qaIsReviewed}
        hasAudio={hasAudio}
        qaReviewedDate={qaReviewedDate}
        qaReviewedByUserName={
          qaReviewedByUserUid ? getUserByUid(qaReviewedByUserUid).name : null
        }
        hasConsent={hasConsent}
      />
      {isCreateCommentOpen && (
        <CreateCommentModal
          onClose={handleCloseCreateComment}
          onCancel={enableKeyboardShortcuts}
          conversationUid={conversationUid}
          agentUid={agentUid}
          initialTime={getTime()}
        />
      )}
    </>
  )
}

export default Audio
